var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-synth":"upsell-modal"}},[_c('div',{ref:"vue_confirm_modal",staticClass:"confirm-modal",attrs:{"tabindex":"-1","role":"dialog"}},[_c('div',{staticClass:"modal-dialog",staticStyle:{"max-width":"420px","z-index":"1010"},attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content p20"},[_c('div',{staticClass:"modal-body text-center text-grey"},[(this.name)?_c('p',{staticClass:"t14"},[_vm._v(" "+_vm._s(_vm.$t('modal.upsell_crust', { name: this.name, currency: this.currency, amount: this.amount }))+" ")]):(!this.hasDrink)?_c('p',{staticClass:"t14"},[_vm._v(" "+_vm._s(_vm.$t('modal.upsell_drink'))+" ")]):(!this.hasDessert)?_c('p',{staticClass:"t14"},[_vm._v(" "+_vm._s(_vm.$t('modal.upsell_dessert'))+" ")]):_vm._e()]),_c('div',{staticClass:"flex"},[_c('button',_vm._g({staticClass:"btn btn-success p10 t17 mr20 ml20 fw600 flex-1",attrs:{"type":"button","data-synth":"upsell-yes"}},
              this.toppingId
                ? {
                    click: function () { return _vm.checkoutAndCloseModal([this$1.toppingId]); }
                  }
                : !this.hasDrink
                ? {
                    click: function () { return _vm.redirectToSlug('drinks'); }
                  }
                : { click: function () { return _vm.redirectToSlug('desserts'); } }
            ),[_vm._v(" "+_vm._s(_vm.$t('button_add'))+" ")]),_c('button',{staticClass:"btn btn-default p10 t17 mr20 ml20 fw600 flex-1",staticStyle:{"background":"#e9ebee"},attrs:{"type":"button","data-synth":"upsell-no","data-dismiss":"modal"},on:{"click":_vm.checkoutAndCloseModal}},[_vm._v(" No ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }