<template>
  <div id="combo-detail">
    <div
      class="header xs-relative xs-fill-white shadow-down-1 xs-z-100 xs-h-40 md-h-60 lg-static lg-width-auto"
      style="display: none"
    >
      <div
        class="lg-container xs-flex-center md-justify-start md-items-start xs-relative xs-full-height"
      >
        <a
          class="xs-flex xs-items-center xs-text-gray-medium xs-absolute xs-l-15 xs-t-0 xs-b-0 xs-opacity-50 close-combo-popup-items"
          href="javascript: void(0);"
          @click="closeGroup"
        >
          <i class="xs-ml-5 icon-caret-left"></i>
        </a>
        <div class="xs-relative xs-z-500 xs-mx-auto">
          <a
            class="header-logo xs-block md-pl-30 xs-fill-white lg-fill-none circle"
            style="padding: 2px;"
            href="javascript: void(0)"
            @click="$router.push({'name': 'home', query: {focusLocalization: true}})"
          >
            <span class="logo"></span>
          </a>
        </div>
      </div>
    </div>
    <div class="combo-details-breadcrumb hidden-xs hidden-sm">
      <div class="mycontainer">
        <div class="combo-infos">
          <a href="javascript: void(0);" @click="back()">
            <i class="glyphicon glyphicon-chevron-left"></i>
            {{$t('cart.back_to_promo')}}
          </a>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div style="margin-top: -1px;">
      <div class="combo-details combo-details-header">
        <div class="mycontainer">
          <div class="combo-image">
            <a
              href="javascript: void(0);"
              @click="$root.$emit('onProcessChangeCategory')"
              type="button"
              class="close close-left pull-left hidden-lg hidden-md"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </a>
            <img
              v-bind:src="(comboDetails.image.desktop_thumbnail) ? comboDetails.image.desktop_detail : $const.IMG_PIZZA_DEFAULT"
              v-if="!empty(comboDetails) && !empty(comboDetails.image) && comboDetails.image.desktop_detail"
            />
            <div class="clearfix"></div>
          </div>
          <div class="combo-infos">
            <div
              class="combo-name lg:remove--hr lg:text-left lg:justify-start"
              :data-synth="'combo-name-' + comboDetails.uuid"
            >{{comboDetails.name}}</div>
            <div
              class="combo-price t18 fw600 pt10"
              v-if="comboDetails.price > 0"
            >{{$const.CURRENCY}}{{formatNumDisplay(comboDetails.price)}}</div>
            <div
              class="combo-description"
              v-if="comboDetails.marketing_description"
            >{{comboDetails.marketing_description}}</div>
            <div class="combo-tc text-grey">
              <div
                role="button"
                @click="showAccordionTC = !showAccordionTC"
                v-if="comboDetails.t_and_c"
              >
                <h6 class="panel-title t14 mt10 mb5">
                  <i class="fa fa-caret-down" v-if="showAccordionTC"></i>
                  <i class="fa fa-caret-up" v-if="!showAccordionTC"></i>
                  <span class="pl-5">{{$t('t_and_c')}}</span>
                </h6>
              </div>
              <collapse class v-model="showAccordionTC" v-if="comboDetails.t_and_c">
                <div class="menu-description t14 p10">
                  <nl2br tag="div" :text="comboDetails.t_and_c" />
                </div>
              </collapse>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <div class="mycontainer-main">
      <div class="main-content list-item-menu">
        <div class="combo-content">
          <div v-for="(cbgroup,index) in comboDetails.groups" :key="index"
               class="combo-group cursor-pointer deal-option--incomplete group-1686 group-index-0"
               :data-synth="`deal-option-${index}`"
               v-bind:data-index="index"
               v-bind:class="{open: cbgroup.isOpen, 'current': activeGroup ===index, 'done' : cbgroup.done }"
               @click.stop="chooseDetails(cbgroup,index)" :set="isGroupChoosen = isGroupChoosed(cbgroup)">
            <div class="text-center" v-if="autoAddedGroupId.indexOf(cbgroup.id) != -1 ">
              <div class="ribbon-tag ml-auto bg-white text-grey" >
                <span class="ribbon-tag-shadow"></span>
                <div class="mt-2"><font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">{{$t('combo_auto_include')}}</font></font>
                </div>
              </div>
              <div class="pull-right">

              </div>
            </div>
            <div class="combo-group-name t16 text-grey" style="clear:both" v-if="!isGroupChoosen">{{cbgroup.name}}</div><!-- -->

            <!-- ############# -->
            <!--begin group combo choosed-->
            <div
              class="combo-group-description t17 text-black"
              data-content="Choose your pizza"
              v-for="(item,idc) in combogroup.filter(item => item.GroupId === cbgroup.id)"
              :key="idc"
            >
            
              <span class="fw600 hide" v-if="!item.item.id">Choose your pizza</span>
              <div v-if="item.GroupId ===cbgroup.id && item.item.id">
                
                <strong class="text-black fw600">{{getMenuItemsName(item.item)}}</strong>
                <span class="pull-right text-black fw600">
                  <span v-if="item.item.choosedSecond.price > 0">+ {{$const.CURRENCY}}{{formatNumDisplay(item.item.choosedSecond.price)}}</span>
                  <span v-if="item.item.price > 0 && !item.item.choosedSecond.price">+ {{$const.CURRENCY}}{{formatNumDisplay(item.item.price)}}</span>
                  <span class="t13" @click.stop="removeGroup(cbgroup.id)" v-if="autoAddedGroupId.indexOf(cbgroup.id) == -1 ">
                      <i class="pull-right fa fa-times-circle text-ccc icon-remove"></i>
                  </span>
                  <span v-else>
                    <i class="fa fa-check mt-20 mr-5">
                    </i>
                  </span>
                </span>

                <!-- <span class="pull-right text-black fw600 t13" v-if="item.GroupId ===cbgroup.id">+{{$const.CURRENCY}} {{formatNumDisplay(item.item.totalPrice)}}</span> -->
              </div>
              <div class="block my-5"  v-if="item.GroupId ===cbgroup.id && item.item.id">
                  <strong class="t15 text-grey" v-if="item.item.choosedSecond && item.item.choosedSecond.name">{{item.item.choosedSecond.name}}</strong>
              </div>
              <div class="block my-5">
                <div
                  v-for="addItem in item.item.Add"
                  :key="genUniqueKey(addItem)"
                  v-if="item.GroupId ===cbgroup.id && item.item.Add.length > 0 && addItem.special == '1' || addItem.special == '2'"
                >
                  <span class="cl-78838c text-capitalize t13">{{convertString(addItem.name)}}</span>
                  <span
                    class="pull-right text-black fw600 t13 pt5"
                    v-if="addItem.prices && getPriceTopping(addItem, item) > 0"
                  >+ {{$const.CURRENCY}}{{formatNumDisplay(getPriceTopping(addItem, item))}}</span>
                </div>
                <div
                  v-for="addItem in item.item.Add"
                  :key="genUniqueKey(addItem)"
                  v-if="item.GroupId ===cbgroup.id && item.item.Add.length > 0 && (addItem.special != '1' && addItem.special != '2')"
                >
                  <span
                    class="cl-78838c text-capitalize t13"
                  >{{$t('cart.add')}} {{convertString(addItem.name.split('-')[0])}}</span>
                  <span
                    class="pull-right text-black fw600 t13 pt5"
                    v-if="addItem.prices && getPriceTopping(addItem, item) > 0"
                  >+ {{$const.CURRENCY}}{{formatNumDisplay(getPriceTopping(addItem, item))}}</span>
                </div>
                <div
                  v-for="noItem in item.item.Remove"
                  :key="genUniqueKey(noItem)"
                  v-if="item.GroupId ===cbgroup.id && item.item.Remove.length > 0"
                >
                  <span
                    class="cl-78838c text-capitalize t13"
                  >{{$t('cart.remove')}} {{convertString(noItem.name.split("-")[0])}}</span>
                </div>
              </div>
            </div>
            <!-- endgroup combo choosed -->

            <!-- ############# -->
            <div class="combo-group-show">
              <span class="add-icon fa-plus-icon" v-if="!cbgroup.done">
                <i class="add-icon__v"></i>
                <i class="add-icon__h"></i>
              </span>
            </div>
            <div class="combo-group-details" :show="cbgroup.isShow">
              <div class="combo-group-variants list-item-menu">
                <div
                  v-for="(menuItems,indexItem) in cbgroup.items"
                  :key="indexItem"
                  class="item-menu cols-4 show-detail-modal while-tooltip"
                  data-toggle="tooltip"
                  v-bind:title="menuItems.main_description"
                  style="padding-bottom: 0px;"
                  @click.stop="choosePizza(menuItems,cbgroup.id)"
                >
                  <div class="display-flex flex-column xs-border">
                    <div class="menu-image">
                      <img
                        v-if="menuItems.image && menuItems.image.desktop_thumbnail"
                        v-bind:src="menuItems.image.desktop_thumbnail"
                        style
                      />
                      <img v-else v-bind:src="$const.IMG_PIZZA_DEFAULT" style />
                    </div>
                    <div class="flag flag--">
                      <span class="flag__inner"></span>
                    </div>
                    <div class="menu-name text-left" style="z-index: 1">
                      {{getMenuItemsName(menuItems)}}
                      <span class="t10 bold label tag tag-"></span>
                    </div>
                    <div class="menu-description hidden-xs" style="z-index: 1">
                      <nl2br
                        v-if="menuItems.marketing_description"
                        tag="div"
                        :text="menuItems.marketing_description"
                      />
                    </div>
                    <div
                      style="z-index: 1;display: block;padding: 10px "
                      v-if="!hasSelection(menuItems)"
                    >
                      <div
                        class="btn btn-success btn-add-single-item"
                        :data-synth="`add-single-item-${menuItems.id}`"
                        style="width: 100%;"
                        v-on:click.stop="choosePizza(menuItems,cbgroup.id)"
                        v-if="requiresCustomization(menuItems)"
                      >
                        {{$t('pizza_commence')}}
                        <span
                          v-if="menuItems.price > 0"
                          class="pull-right"
                        >{{ $const.CURRENCY }}{{ formatNumDisplay(menuItems.price)}}</span>
                      </div>
                      <div
                        class="btn btn-success btn-add-single-item"
                        :data-synth="`add-single-item-${menuItems.id}`"
                        style="width: 100%;"
                        v-on:click.stop="addToBasket({...menuItems, toppings: { extra: [], remove: [] }}, cbgroup.id, index)"
                        v-if="!requiresCustomization(menuItems)"
                      >
                        {{$t('button_add')}}
                        <span
                          v-if="menuItems.price > 0"
                          class="pull-right"
                        >{{ $const.CURRENCY }}{{ formatNumDisplay(menuItems.price)}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="bg-white modal__actions-fixed" v-if="missingGroup">
        <div class="mycontainer">
          <div class="text-center modal-action-btn">
            <div type="button"
              :class="`btn btn-success btn-add-to-cart${loading ? ' disabled' : ''}`"
              @click="addTocart()"
              data-synth="combo-details-add-to-cart-button">
              <span class="pull-left">{{$t('cart.add_deal_to_my_basket')}}</span>
              <span
                class="pull-right combo-price"
                v-if="totalPrice>0"
              >+ {{$const.CURRENCY}}{{formatNumDisplay(totalPrice)}}</span>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<style lang='scss' scoped>
@import '../../assets/css/combo_details.css';
</style>
<script type="text/javascript">
import { mapState } from 'vuex'
import ModalCombo from '../popup/ModalCombo.vue'

export default {
  mounted() {
    let vm = this
    this.$cart.init(this)

    if (document.documentElement.clientWidth < 768) {
      document.body.classList.add('mobile')
    }

    if (!this.checkPosition(this)) {
      this.$router.push({ name: 'home', query: {focusLocalization: 1} })
    } else {
      if (this.$route.params.coupon) {
        this.coupon = this.$route.params.coupon
        let item = this.$cart.get(this)
        if (
          !item.current_coupon_item ||
          item.current_coupon_item.uuid != this.$route.params.id ||
          item.current_coupon_item.coupon != this.$route.params.coupon
        ) {
          this.$root.$emit('onProcessChangeCategory')
        }
        this.getComboDetailsFreeItem(item.current_coupon_item.menu[0])
      } else {
        this.getComboDetails()
      }

      this.$root.$on('responseComboGroup', (obj, id) => {
        vm.changeCombo(obj, id)
        vm.checkMissingGroup()
        vm.calTotalPrice()
        vm.closeGroup()
      })
    }
  },
  computed: mapState(['loading']),
  methods: {
    requiresCustomization(item) {
      return item.min_extra > 0
    },
    isGroupChoosed(cGroup){
       return this.combogroup.find(choosenGroup => choosenGroup.GroupId == cGroup.id && Object.keys(choosenGroup.item).length>0) != undefined;
    },
    getMenuItemsName(menuItems) {
      let menu = null

      if (!menuItems.customize) {
        const groupItems = this.comboDetails.groups
        if (groupItems && groupItems.length > 0) {
          for (let i = 0; i < groupItems.length; i++) {
            if (menu) {
              break
            }
            menu = groupItems[i].items.filter(i => i.uuid === menuItems.uuid)[0]
          }
        }
      } else {
        menu = menuItems
      }
      if (menu) {
        const { customize } = menu
        if (customize) {
          const { combos } = customize
          if (combos) {
            const combo = combos.filter(c => c.combo_uuid === this.comboDetails.uuid)[0]
            return this.convertString(combo ? combo.rules.name[this.lang] : menuItems.name)
          }
        }
      }
      return this.convertString(menuItems.name)
    },
    setSelectedFromCart(){
      //Check if combo is in cart
      const possibleCombo = this.cart.data.find(
        c => c.id == this.$route.params.ssid
      )

      if (possibleCombo) {
        possibleCombo.items.forEach(item => {
          item.group_items.forEach(groupItem => {
            const actualGroup = this.comboDetails.groups.find(
              group => group.id === item.group_id
            )
            const actualItem = actualGroup.items.find(
              i => i.id === groupItem.product_id
            )

            const customToppings = {
              default: actualItem.toppings.default,
              extra: groupItem.custom_toppings.extra.map(t =>
                actualItem.toppings.extra.find(t2 => t2.id == t)
              ),
              remove: groupItem.custom_toppings.remove.map(t =>
                actualItem.toppings.default.find(t2 => t2.id == t)
              )
            }

            const extraLayerInfo = {}
            if (groupItem.layer_info.first && groupItem.layer_info.second) {
              const chosenFirst = actualItem.first_layers.find(
                l => groupItem.layer_info.first.id === l.id
              )

              if (chosenFirst) {
                const chosenSecond = chosenFirst.second_layers.find(
                  l => groupItem.layer_info.second.id === l.id
                )

                extraLayerInfo.first_layers = [
                  {
                    ...chosenFirst,
                    second_layers: [chosenSecond]
                  }
                ]
              }
            }

            this.addToBasket(
              {
                ...actualItem,
                toppings: customToppings,
                ...extraLayerInfo
              },
              item.group_id,
              0
            )
          })
        })
      }
    },
    hasSelection(item) {
      if (item && item.first_layers && item.first_layers !== undefined) {
        // return item.first_layers.length >= 1 || item.first_layers.length === 1 && item.first_layers[0].second_layers.length > 1
        if (item.first_layers.length === 1 && (item.first_layers[0].second_layers && item.first_layers[0].second_layers.length === 1 || item.first_layers[0].second_layers.length == 0)) {
            return false
        } else return (
          item.first_layers.length > 1 ||
          (item.first_layers[0] &&
            item.first_layers[0].second_layers.length > 1)
        )
      }
      return false
    },
    /**
     * Get topping price
     * */
    getPriceTopping(add, combo) {
      const { item } = combo
      return add.prices[item.choosedFirst.sku]
        ? add.prices[item.choosedFirst.sku]
        : 0
    },
    changeCombo(obj, id) {
      for (let i in this.combogroup) {
        for (let o in obj) {
          if (this.combogroup[i].GroupId === obj[o].GroupId) {
            this.combogroup[i]['item'] = obj[o].item
            this.$forceUpdate(this.combogroup[i], 'item', obj[o].item)
          }
        }
      }
      for (let j in this.comboDetails.groups) {
        if (this.comboDetails.groups[j].id === id) {
          this.comboDetails.groups[j].done = true
        }
      }
    },
    getComboDetails() {
      let vm = this

      let comboId = this.$route.params.id
      let data = {
        tier: this.$disposition.getByKey(this, 'tier', '1'),
        order_type: this.$disposition
          .getByKey(this, 'orderType', 'D')
          .charAt(0),
        outlet_code: this.$disposition.getByKey(this, 'outletCode', null),
        order_time: this.convertDateTimeToUTC(
          this.$disposition.getByKey(this, 'collectionTimeText', '')
        )
      }
      // let data = {'categoryID': categoryId, 'tier': this.$disposition.getByKey(this, 'tier', '0'), 'orderType': this.$disposition.getByKey(this, 'orderType', 'N').charAt(0)}
      this.axios.getComboDetails(comboId, data, function(response) {
        vm.comboDetails = response.data.data.item
        if (vm.empty(vm.comboDetails)) {
          vm.$notify({
            type: 'error',
            title: vm.$t('combo_detail_notfound')
          })
          vm.$root.$emit('onProcessChangeCategory')
          return false
        }
        vm.eventTrackingProductDetail(
          vm,
          vm.comboDetails,
          vm.$const.CARD_ITEM_TYPE.COMBO,
          'product-details'
        )
        // Add data for cart item
        vm.$cart.tier = data.tier
        vm.$cart.order_type = data.order_type
        // Add data for cart item
        vm.getComboGroup()
        vm.checkMissingGroup()
        vm.autoAddItemGroup()

        // vm.comboDetails.groups[0].isOpen = true
        vm.autoOpenGroup()

        vm.setSelectedFromCart()
      })
    },
    getComboDetailsFreeItem(item) {
      this.comboDetails = item
      this.getComboGroup()
      this.checkMissingGroup()
    },
    closeGroup() {
      for (let i in this.comboDetails.groups) {
        this.comboDetails.groups[i]['isShow'] = false
        this.comboDetails.groups[i]['isOpen'] = false
      }
      this.activeGroup = null
      document.body.classList.remove('combo-popup-items')
    },
    chooseDetails(obj, index, autoSelect = false) {
      let actualItem, actualGroup, actualItemIndex;

      actualGroup = this.comboDetails.groups.find(
        group => group.id === obj.id
      )

      actualGroup.items.forEach(
        (item, i) => {
          if(item.id == this.combogroup[index].item.id){
            actualItem = item;
            actualItemIndex = i;
          }
        }
      )

      let isMobile = false
      if (document.documentElement.clientWidth < 768) {
        isMobile = true
      }
      /* if (this.activeGroup ===index && document.body.classList.contains('combo-popup-items') && document.documentElement.clientWidth < 768) {
        return false
      } */

      let openComboDetails = true
      for (let index in this.combogroup) {
        let choosenGroup = this.combogroup[index]
        if (
          choosenGroup.GroupId == obj.id &&
          Object.keys(choosenGroup.item).length > 0
        ) {
          openComboDetails = false
        }
      }
      if (!openComboDetails) {
        obj.isOpen = false
        this.choosePizza(obj.items[actualItemIndex], obj.id)
      } else {
        obj.isOpen = !obj.isOpen
      }

      // this.$set(obj, 'isShow', !obj.isShow)
      this.activeGroup = index
      if (isMobile) {
        obj.isOpen = true
        this.eventTrackingModuleLoadDeal(this, this.comboDetails.groups[index])

        if (!autoSelect) {
          document.body.classList.add('combo-popup-items')
        }
      } else {
        if (!obj.isOpen) {
          document.body.classList.remove('combo-popup-items')
          this.activeGroup = null
        } else {
          this.eventTrackingModuleLoadDeal(
            this,
            this.comboDetails.groups[index]
          )
          if (!autoSelect) {
            document.body.classList.add('combo-popup-items')
          }
        }
      }
    },
    /**
     * Trigger before open modal popup
     * */
    beforeOpen(event) {
      document.body.classList.add('modal-open')
    },
    /**
     * Trigger before close modal popup
     * */
    beforeClose(event) {
      document.body.classList.remove('modal-open')
    },
    choosePizza(obj, id) {
      if(obj.first_layers && obj.first_layers.length >=1 && obj.first_layers[0].second_layers && obj.first_layers[0].second_layers.length > 0) {
        this.$modal.show(
          ModalCombo,
          {
            menu: obj,
            sizecrust: this.comboDetails.sizecrustimages,
            extracheese: this.comboDetails.extracheese,
            cGroup: JSON.parse(JSON.stringify(this.combogroup)),
            id: id,
            comboUUID: this.$route.params.id,
            cartItemId: this.$route.params.ssid,
            comboName: this.comboDetails.name
          },
          {
            class: 'modal-menu',
            transition: 'nice-modal-fade',
            overlayTransition: 'overlay-fade'
          },
          {
            'before-open': this.beforeOpen,
            'before-close': this.beforeClose
          }
        )
      }
    },
    getComboGroup() {
      for (let gr in this.comboDetails.groups) {
        // if (this.comboDetails.groups[gr].items.length > 1) {
        this.combogroup.push({
          GroupUUID: this.comboDetails.groups[gr].uuid,
          GroupId: this.comboDetails.groups[gr].id,
          item: {}
        })
        // }
      }
    },
    checkMissingGroup() {
      let groupItem = this.combogroup.length
      let addItem = 0
      for (let k in this.combogroup) {
        if (
          this.combogroup[k].item.name !== undefined &&
          this.combogroup[k].item.name.length > 0
        ) {
          addItem++
        }
      }
      this.missingGroup = addItem === groupItem
    },
    calTotalPrice() {
      let total = 0
      this.totalPrice = 0
      total += parseFloat(this.comboDetails.price)
      for (let k in this.combogroup) {
        total += parseFloat(this.combogroup[k].item.totalPrice)
      }
      this.totalPrice = total
    },
    removeGroup(id) {
      for (let k in this.combogroup) {
        if (this.combogroup[k].GroupId === id) {
          this.combogroup[k]['item'] = {}
        }
      }
      for (let j in this.comboDetails.groups) {
        if (this.comboDetails.groups[j].id === id) {
          this.comboDetails.groups[j].done = false
        }
      }
      this.checkMissingGroup()
    },
    addToBasket(menu, groupID, idx) {
      if (!menu) {
        return false
      }
      let firstSelected = {}
      let secondSelected = {}
      let price = 0
      price += parseFloat(menu.price > 0 ? menu.price : 0)
      if (menu.first_layers && menu.first_layers.length > 0) {
        firstSelected = menu.first_layers[0]
        price += parseFloat(firstSelected.price > 0 ? firstSelected.price : 0)
        if (
          firstSelected.second_layers &&
          firstSelected.second_layers.length > 0
        ) {
          secondSelected = firstSelected.second_layers[0]
          price += parseFloat(
            secondSelected.price > 0 ? secondSelected.price : 0
          )
        }
      }
      let cGroup = JSON.parse(JSON.stringify(this.combogroup))
      for (let cb in cGroup) {
        if (cGroup[cb].GroupId == groupID) {
          cGroup[cb].item.name = menu.name + ' ' + (firstSelected.name != undefined ? firstSelected.name : '')
          cGroup[cb].item.id = menu.id
          cGroup[cb].item.single_uuid = menu.single_uuid
          cGroup[cb].item.choosedSecond = secondSelected
          cGroup[cb].item.choosedFirst = firstSelected
          cGroup[cb].item.price = price
          cGroup[cb].item.totalPrice = price

          if (menu.toppings) {
            cGroup[cb].item.Default = menu.toppings.default || []
            cGroup[cb].item.Remove = menu.toppings.remove || []
            cGroup[cb].item.Add = menu.toppings.extra || []
          } else {
            cGroup[cb].item.Default = []
            cGroup[cb].item.Remove = []
            cGroup[cb].item.Add = []
          }

          cGroup[cb].item.uuid = menu.uuid
          cGroup[cb].item.CheesePrice = 0
          cGroup[cb].item.image =
            menu.image && menu.image.desktop_thumbnail
              ? menu.image.desktop_thumbnail
              : ''
        }
      }
      this.closeGroup()

      let pro = {
        item: menu,
        id: menu.uuid,
        uuid: menu.uuid,
        combo_uuid: this.$route.params.id,
        name: menu.name,
        price: price,
        first: firstSelected || {},
        second: secondSelected || {}
      }
      if (idx) {
        this.evenTrackingProductClick(this, pro, 'add-to-deal')
      }
      this.$root.$emit('responseComboGroup', cGroup, groupID)
    },
    addTocart() {
      if (this.loading) { return }
      let item = {
        group: {},
        selling_start: this.comboDetails.selling_start,
        products: []
      }
      if (this.coupon) {
        item.group.coupon_code = this.coupon
      }
      item.group['outlet_code'] = this.getOutletCode(this)
      item.group['tier'] = this.$cart.tier
      item.group['order_type'] = this.$cart.order_type
      item.group['order_time'] = this.getTimePickerDate(this)
      item.group['product_type'] = this.$const.CARD_ITEM_TYPE.COMBO
      item.group['combo_id'] = this.comboDetails.uuid
      item.group['quantity'] = 1
      item.group['category_uuid'] = this.$store.getters.category_uuid

      let pro = {
        items: [],
        id: this.comboDetails.uuid,
        name: this.comboDetails.name,
        price: this.totalPrice
      }
      for (let i in this.combogroup) {
        let obj = this.combogroup[i].item
        obj.category = this.comboDetails.groups[i].items[0].category.name_seo
        let product = {}
        product.category_id = null
        product.combo_group_id = this.combogroup[i].GroupUUID
        product.product_id = obj.uuid
        product.first_id = obj.choosedFirst.uuid
        product.second_id = obj.choosedSecond.uuid
        product.toppings = {}
        product.toppings.remove = this.$cart.getToppingById(obj.Remove)
        product.toppings.extra = this.$cart.getToppingById(obj.Add)
        item.products.push(product)
        pro.items.push({
          item: obj,
          first: obj.choosedFirst,
          second: obj.choosedSecond
        })
      }
      if (!this.$route.params.ssid || this.$route.params.ssid === 'coupon') {
        this.$cart.insert(this, item)
        this.eventTrackingAddToCart(
          this,
          pro,
          'main',
          this.$const.CARD_ITEM_TYPE.COMBO
        )
      } else {
        item.group['item_id'] = this.$route.params.ssid
        this.$cart.updateCart(this, item)
      }
      this.$cart.clearFreeItem(this)
      this.$root.$emit('onProcessChangeCategory')
    },
    getImage(data) {
      if (!this.empty(data) && data.image.length > 0) {
        return data.image[0].icon
      }
      return ''
    },
    /*
      Automatic add item when the group only 1 item
     */
    autoAddItemGroup() {
      for (let group of this.comboDetails.groups) {
        let choose = false
        if (group.items.length == 1) {
          if (
            !group.items[0].first_layers ||
            !group.items[0].first_layers.length
          ) {
            choose = true
          } else if (
            group.items[0].first_layers.length == 1 &&
            (!group.items[0].first_layers[0].second_layers ||
              group.items[0].first_layers[0].second_layers.length <= 1)
          ) {
            choose = true
          }
          if (group.items.some(x => x.sku.includes('cyo'))) {
            choose = false
          }
          if (choose) {
            this.addToBasket({
              ...group.items[0],
              toppings: {
                extra: [],
                remove: []
              }
            }, group.id);
            this.autoAddedGroupId.push(group.id);
          }
        }
      }
    },
    /**
     * Automatic open group combo
     */
    autoOpenGroup() {
      let flag = false
      for (let i in this.comboDetails.groups) {
        if (
          this.comboDetails.groups[i].items.length > 1 ||
          !this.hasSelection(this.comboDetails.groups[i].items[0])
        ) {
        } else {
          this.chooseDetails(this.comboDetails.groups[i], i, true)
          break
        }
      }
    }
  },
  data() {
    return {
      showAccordionTC: false,
      combogroup: [],
      missingGroup: false,
      activeGroup: false,
      totalPrice: 0,
      coupon: '',
      comboDetails: {},
      autoAddedGroupId: [],
      lang: this.$session.get('lang') || process.env.VUE_APP_DEFAULT_LANGUAGE
    }
  },
  watch: {
    missingGroup(newValue, oldValue) {
      if (newValue) {
        this.eventTrackingProductView(null, this.comboDetails)
      }
    }
  }
}
</script>
<style>
.modal__actions-fixed {
  -webkit-box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.05);
  -webkit-transform: translateZ(0);
  background: #fff;
  bottom: 0;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.05);
  height: auto;
  left: 0;
  padding: 5px;
  position: fixed;
  right: 0;
  z-index: 10;
}
</style>
