<template>
  <div id="modal-menu" data-synth="modal-combo">
    <div class="modal-content">
      <button
        type="button"
        class="close close-left"
        data-dismiss="modal"
        aria-label="Close"
        @click="$emit('close')"
      >
        <span aria-hidden="true">×</span>
      </button>
      <form id="form-add-menu" method="post" action="#">
        <div class="modal-body display-flex flex-column">
          <div class="modal-head pb-20">
            <div class="image-cover sides">
              <img
                v-bind:src="
                  menu.image.desktop_detail
                    ? menu.image.desktop_detail
                    : $const.IMG_PIZZA_DEFAULT
                "
              />
            </div>
            <h1 class="t22 fw600 title-name">{{ modalName }}</h1>
            <!-- <p class="t13 fw300 pr10 pt10 text-black" style="">
              <nl2br v-if="menu.marketing_description" tag="p" :text="menu.marketing_description"/>
            </p>-->
            <p class="t13 fw300 pr10 pt10 text-black" style>
              <nl2br
                v-if="menu.main_description"
                tag="p"
                :text="menu.main_description"
              />
            </p>
            <div
              role="button"
              @click="showAccordionTC = !showAccordionTC"
              v-if="menu.t_and_c"
            >
              <h6 class="panel-title t14 text-grey">
                <i class="fa fa-caret-down" v-if="showAccordionTC"></i>
                <i class="fa fa-caret-up" v-if="!showAccordionTC"></i>
                <span>{{ $t('t_and_c') }}</span>
              </h6>
            </div>
            <collapse v-model="showAccordionTC" v-if="menu.t_and_c">
              <div class="menu-description p10 text-grey">
                {{ menu.t_and_c }}
              </div>
            </collapse>
            <span
              class="add-toppings-str pl-10"
              v-if="addExtraTopping.length > 0"
            >
              <span class="text-green">
                <strong
                  >{{ $t('cart.add') }}
                  {{
                    convertString(addExtraTopping.map(f => fixFreeToppingName(f.name)).join(', '))
                  }}</strong
                >
              </span>
              <span class="content text-capitalize"></span>
            </span>
            <span
              class="remove-toppings-str pl-10"
              v-if="removeYourTopping.length > 0"
            >
              <span class="text-orange">
                <strong
                  >{{ $t('cart.remove') }}
                  {{
                    convertString(removeYourTopping.map(f => fixFreeToppingName(f.name)).join(', '))
                  }}</strong
                >
              </span>
              <span class="content text-capitalize"></span>
            </span>
          </div>
          <div class="flex1auto selector-container pb-100">
            <!-- ############################## Choose size & crust ########################## -->
            <transition
              name="fade"
              v-if="menu && menu.first_layers && menu.first_layers.length > 0"
            >
              <div class="box-select-variant-addon" v-if="!choosedName">
                <p
                  class="fw600 t15 mb15 text-black bg-grey-lightest md:bg-transparent p10"
                >
                  {{ $t('menu.select_size_crust') }}
                </p>
                <div v-for="(item, fin) in menu.first_layers" :key="fin">
                  <div
                    class="t15 fw600 heading-line-right mt10 mb10 text-black p10 bg-white md:bg-transparent"
                  >
                    {{ item.name }}
                  </div>
                  <div
                    v-for="(subitem, sin) in item.second_layers"
                    :key="sin"
                    :data-synth="`crust-${subitem.name}`"
                    class="variant-addon-item option display-flex t15 p10 text-black bg-grey-lightestest md:bg-transparent active"
                    @click="choosetopping(item, subitem)"
                  >
                    <div
                      class="flex1 display-flex items-center justify-start text-black fw400"
                    >
                      <div>
                        <img
                          v-if="subitem.image"
                          width="35"
                          v-bind:alt="subitem.name"
                          v-bind:src="
                            Object.keys(subitem.image).length > 0
                              ? subitem.image.desktop_thumbnail
                              : (subitem.second_layer.image
                              ? subitem.second_layer.image.desktop_thumbnail
                              : $const.IMG_CRUST_DEFAULT)
                          "
                          class="option---icon"
                        />
                      </div>
                      <div class="pl-10 option---content">
                        <div>{{ subitem.name }}</div>
                        <div class="option__long-description t13 fw400"></div>
                      </div>
                    </div>
                    <div class="fw600 text-right pl10 pr10 flex-center" v-if="sumNum(menu.price, item.price, subitem.price) > 0">
                      + {{$const.CURRENCY}}{{ formatNumDisplay(sumNum(menu.price, item.price, subitem.price)) }}
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <!-- ################################################################################### -->
            <!-- ############################ Topping ###################################### -->
            <div
              class="pt15 pb10 pl20 pr20 box-topping-contain"
              v-if="
                choosedName &&
                  menu &&
                  menu.first_layers &&
                  menu.first_layers.length > 0
              "
            >
              <div class="variant-addon-choosen">
                <div class="display-flex t14">
                  <span class="flex1 name fw400 text-black">{{
                    convertString(choosedName)
                  }}</span>
                  <!-- <span class="flex2 price fw600  cl-525252 mr10 hidden">RM 5.30</span> -->
                  <a
                    @click="changeSize"
                    class="cursor-pointer cl-78838c border-b"
                    >{{ $t('button_change') }}</a
                  >
                </div>
                <hr />
              </div>

              <div class="menu-toppings option-group">
                <div class="topping-header">
                  {{ $t('toppings_change_header') }}
                </div>
                <!-- Default Sauce -->
                <collapse class="option-group" v-model="showSauceAccordion">
                  <div
                    class="extra-sauce option option-sauce md:bg-transparent"
                    v-if="!showSauceChooser"
                    @click="toggleSauceChooser"
                  >
                    <div class="xs-mt-20">
                      <div class="extra-sauce-option flex1">
                        <label
                          class="xs-items-center rounded label-radio"
                          :for="choosedSauce.uuid"
                        >
                          <input
                            class="xs-block rounded xs-p-5 radio ExtraSauce"
                            :id="choosedSauce.uuid"
                            name="ExtraSauce"
                            checked
                            type="radio"
                          />
                          <i class="pull-left mt-12"></i>
                          <span class="xs-mr-auto xs-items-center">
                            <i class="xs-hide md-block xs-mr-10"></i>
                            <span
                              class="fw400 t14"
                              style="display: inline-block; vertical-align: middle;"
                              >{{ choosedSauce.name }}</span
                            >
                          </span>
                          <div class="pull-right">
                            <span
                              class="extra-sauce-price t15 fw600 cl-525252"
                              v-if="getPriceTopping(choosedSauce.prices) > 0"
                            >
                              +
                              {{
                                $t('currency_display', {
                                  currency: $const.CURRENCY,
                                  amount: formatNumDisplay(
                                    getPriceTopping(choosedSauce.prices)
                                  )
                                })
                              }}
                            </span>
                            <a
                              class="cl-78838c border-b fw400 t14 xs-align-middle"
                              >{{ $t('button_change') }}</a
                            >
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    class="extra-sauce choose-sauce pt-12"
                    v-if="showSauceChooser"
                  >
                    <span class="t17" style="font-family: 'SharpSemiBold Sans'">{{
                      $t('toppings_your_sauce')
                    }}</span>

                    <div
                      class="option option-sauce md:bg-transparent"
                      v-for="(topping, index) in extraTopping.filter(
                        t => t.special == 2
                      )"
                      :key="'extraSauce_' + index"
                    >
                      <div class="xs-mt-20">
                        <div class="extra-sauce-option">
                          <label
                            class="xs-items-center rounded label-radio"
                            :for="topping.uuid"
                            @click="chooseSauce(topping)"
                          >
                            <input
                              class="xs-block rounded xs-p-5 radio ExtraSauce"
                              :id="topping.uuid"
                              name="ExtraSauce"
                              :value="topping.sku"
                              :checked="choosedSauce.sku == topping.sku"
                              type="radio"
                            />
                            <i class="pull-left mt-12"></i>
                            <span class="xs-mr-auto xs-items-center">
                              <i class="xs-hide md-block xs-mr-10"></i>
                              <span class="fw400 t14">{{ topping.name }}</span>
                            </span>
                            <span
                              class="pull-right extra-sauce-price t15 fw600 cl-525252 xs-align-middle"
                              v-if="getPriceTopping(topping.prices) > 0"
                            >
                              +
                              {{
                                $t('currency_display', {
                                  currency: $const.CURRENCY,
                                  amount: formatNumDisplay(
                                    getPriceTopping(topping.prices)
                                  )
                                })
                              }}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </collapse>

                <!-- Default Cheese -->
                <collapse class="option-group" v-model="showCheeseAccordion">
                  <div
                    class="extra-cheese option option-cheese md:bg-transparent"
                    v-if="!showCheeseChooser"
                    @click="toggleCheeseChooser"
                  >
                    <div class="xs-mt-20">
                      <div class="extra-cheese-option flex1">
                        <label
                          class="xs-items-center rounded label-radio"
                          :for="choosedCheese.uuid"
                        >
                          <input
                            class="xs-block rounded xs-p-5 radio ExtraCheese"
                            :id="choosedCheese.uuid"
                            name="ExtraCheese"
                            checked
                            type="radio"
                          />
                          <i class="pull-left mt-12"></i>
                          <span class="xs-mr-auto xs-items-center">
                            <i class="xs-hide md-block xs-mr-10"></i>
                            <span
                              class="fw400 t14"
                              style="display:inline-block; vertical-align:middle"
                              >{{ choosedCheese.name }}</span
                            >
                          </span>
                          <div class="pull-right">
                            <span
                              class="extra-cheese-price t15 fw600 cl-525252"
                              v-if="getPriceTopping(choosedCheese.prices) > 0"
                            >
                              +
                              {{
                                $t('currency_display', {
                                  currency: $const.CURRENCY,
                                  amount: formatNumDisplay(
                                    getPriceTopping(choosedCheese.prices)
                                  )
                                })
                              }}
                            </span>
                            <a
                              class="cl-78838c border-b fw400 t14 xs-align-middle"
                              >{{ $t('button_change') }}</a
                            >
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    class="extra-cheese choose-cheese pt-12"
                    v-if="showCheeseChooser"
                  >
                    <span class="t17" style="font-family: 'SharpSemiBold Sans'">{{
                      $t('toppings_your_cheese')
                    }}</span>

                    <div
                      class="option option-cheese md:bg-transparent"
                      v-for="(topping, index) in extraTopping.filter(
                        t => t.special == 1
                      )"
                      :key="'extraCheese_' + index"
                    >
                      <div class="xs-mt-20">
                        <div class="extra-cheese-option">
                          <label
                            class="xs-items-center rounded label-radio"
                            :for="topping.uuid"
                            @click="chooseCheese(topping)"
                          >
                            <input
                              class="xs-block rounded xs-p-5 radio ExtraCheese"
                              :id="topping.uuid"
                              name="ExtraCheese"
                              :value="topping.sku"
                              :checked="choosedCheese.sku == topping.sku"
                              type="radio"
                            />
                            <i class="pull-left mt-12"></i>
                            <span class="xs-mr-auto xs-items-center">
                              <i class="xs-hide md-block xs-mr-10"></i>
                              <span class="fw400 t14">{{ topping.name }}</span>
                            </span>
                            <span
                              class="pull-right extra-cheese-price t15 fw600 cl-525252 xs-align-middle"
                              v-if="getPriceTopping(topping.prices) > 0"
                            >
                              +
                              {{
                                $t('currency_display', {
                                  currency: $const.CURRENCY,
                                  amount: formatNumDisplay(
                                    getPriceTopping(topping.prices)
                                  )
                                })
                              }}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </collapse>

                <div v-if="hasNonSpecialToppings(yourTopping)">
                  <div
                    class="topping-header"
                    style
                    v-if="yourTopping && yourTopping.length > 0"
                    @click="showToppingMinus = !showToppingMinus"
                    :class="{ active: showToppingMinus }"
                  >
                    {{ $t('your_toppings') }}
                    <i data-v-38def44e class="fa fa-caret-up"></i>
                  </div>
                  <collapse v-model="showToppingMinus">
                    <div
                      class="your-toppings text-black"
                      v-if="yourTopping && yourTopping.length > 0"
                    >
                      <div
                        class="text-red text-center pt10 pb10"
                        v-if="removeYourTopping.length >= maxRemoval && maxRemoval > 0"
                      >
                        {{
                          $t('modal.topping_removal_info', { max: maxRemoval })
                        }}
                      </div>
                      <transition-group
                        name="fade"
                        v-on:before-leave="beforeLeaveTopping"
                        v-on:after-leave="afterLeaveTopping"
                      >
                        <div
                          class="your-toppings-item display-flex t14 p10 cursor-pointer option visible"
                          v-for="(topping, index) in yourTopping"
                          :key="'yourTopping' + index"
                          @click="changeTopping(topping, 1, index)"
                        >
                          <div class="flex1 fw400">
                            <img
                              class="ml5"
                              v-bind:src="
                                topping.image
                                  ? topping.image.desktop_thumbnail
                                  : null
                              "
                              width="24"
                              height="2"
                            />
                            <span class="pl-5">{{
                              convertString(fixFreeToppingName(topping.name))
                            }}</span>
                          </div>
                          <div
                            class="text-right pl10 pr10 topping-price fw600 cl-525252"
                            v-if="
                              !topping.default &&
                                getPriceTopping(topping.prices) > 0
                            "
                          >
                            {{
                              $t('currency_display', {
                                currency: $const.CURRENCY,
                                amount: formatNumDisplay(
                                  getPriceTopping(topping.prices)
                                )
                              })
                            }}
                          </div>
                          <div
                            class="bold text-right pl10 pr10"
                            style="align-self: center;"
                          >
                            <img
                              v-if="!topping.isDisable"
                              src="../../assets/images/icons/remove.svg"
                              class="cursor-pointer"
                              alt="remove"
                            />
                          </div>
                        </div>
                      </transition-group>
                    </div>
                  </collapse>
                </div>

                <div v-if="hasNonSpecialToppings(extraTopping) && extraTopping.some(t => t.special == 0)">
                  <div
                    class="topping-header"
                    style
                    v-if="extraTopping && extraTopping.length > 0"
                    @click="showToppingPlus = !showToppingPlus"
                    :class="{ active: showToppingPlus }"
                  >
                    {{ $t('add_toppings') }}
                    <i data-v-38def44e class="fa fa-caret-up"></i>
                  </div>
                  <collapse v-model="showToppingPlus">
                    <div
                      class="add-toppings text-black"
                      v-if="extraTopping && extraTopping.length > 0"
                    >
                      <transition-group name="fade">
                        <div
                          v-for="(extopping, index) in extraTopping.filter(
                            t =>
                              t.special != '1' &&
                              t.special != '2' &&
                              t.special != '3'
                          )"
                          :key="'extraTopping_' + index"
                          :data-synth="`topping-${index}`"
                          class="add-toppings-item display-flex t14 p10 cursor-pointer option bg-grey-lightestest md:bg-transparent"
                          v-bind:class="{ disable: extopping.isDisable }"
                          @click="changeTopping(extopping, 2, index)"
                        >
                          <div class="flex1 fw400">
                            <img
                              class="ml5"
                              v-bind:src="
                                extopping.image
                                  ? extopping.image.desktop_thumbnail
                                  : null
                              "
                              width="24"
                              height="24"
                            />
                            <span class="pl-5">{{
                              convertString(fixFreeToppingName(extopping.name))
                            }}</span>
                          </div>
                          <div
                            class="text-right pl10 pr10 topping-price fw600 cl-525252"
                            v-if="
                              !extopping.default &&
                                getPriceTopping(extopping.prices) > 0
                            "
                          >
                            {{
                              $t('currency_display', {
                                currency: $const.CURRENCY,
                                amount: formatNumDisplay(
                                  getPriceTopping(extopping.prices)
                                )
                              })
                            }}
                          </div>
                          <div
                            class="bold text-right pl10 pr10"
                            style="align-self: center;"
                          >
                            <span class="add-icon mr-0 ml-5 fa-plus-icon">
                              <i class="add-icon__v"></i>
                              <i class="add-icon__h"></i>
                            </span>
                            <img
                              src="../../assets/images/icons/remove.svg"
                              class="cursor-pointer fa-remove-image"
                              alt="remove"
                              style="display: none"
                            />
                          </div>
                        </div>
                      </transition-group>
                    </div>
                  </collapse>
                </div>
              </div>
            </div>
            <!-- ############################################################################ -->
          </div>
          <div class="modal-action" v-if="!disableAdd">
            <div v-if="this.errorMessage">
              <span class="cl-red">{{ this.errorMessage }}</span>
            </div>
            <div>
              <button
                type="button"
                data-synth="action-btn-add"
                class="btn btn-lg btn-success full js-action-btn-add"
                v-bind:class="{ disabled: !isValidItem() }"
                :disabled="!isValidItem()"
                @click="preBasket()"
              >
                {{ $t('add_to_my_promotion') }}
                <span v-if="extraPrice && extraPrice > 0">
                  {{ $const.CURRENCY }}
                  <span class="total-price">{{
                    formatNumDisplay(extraPrice)
                  }}</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ModalUpsellConfirmation from '../popup/ModalUpsellConfirmation'
export default {
  props: [
    'menu',
    'sizecrust',
    'extracheese',
    'cGroup',
    'id',
    'comboUUID',
    'cartItemId',
    'comboName'
  ],
  mounted() {
    this.overrideCustomComboConfigurations()
    this.getTopping()
    this.resizePopup()
    this.updateTotalPrice()
    this.eventTrackingProductDetail(
      this,
      this.menu,
      this.$const.CARD_ITEM_TYPE.COMBO,
      'product-list',
      false,
      'deal',
      this.comboName
    )

    this.setModalFromCartProduct()
    this.updateDisableRemoveTopping()
    this.updateDisableExtraTopping()
  },
  watch: {},
  data: function() {
    return {
      lang: this.$session.get('lang') || process.env.VUE_APP_DEFAULT_LANGUAGE,
      showAccordionTC: false,
      choosedName: '',
      defaultsTopping: [],
      defaultsextraTopping: [],
      yourTopping: [],
      extraTopping: [],
      choosedFirst: {},
      isShowTopping: false,
      removeYourTopping: [],
      addExtraTopping: [],
      totalPrice: 0,
      extraPrice: 0,
      totalExtraTopping: 0,
      transitionLeave: true,
      choosedCheese: [],
      choosedSauce: [],
      gotCheese: { status: 'N', price: 0 },
      maxExtra: this.menu.max_extra,
      // max_topping is actually max_removal
      maxRemoval: this.menu.max_topping != undefined ? this.menu.max_topping : this.$const.TOPPING.MAX_REMOVAL,
      choosedSecond: {},
      disableAdd: false,
      showToppingMinus: true,
      showToppingPlus: true,
      showCheeseChooser: true,
      showSauceChooser: true,
      showSauceAccordion: true,
      showCheeseAccordion: true,
      errorMessage: '',
      modalName: this.menu.name
    }
  },
  methods: {
    isSpecialTopping(topping) {
      return topping.special == 1 || topping.special == 2
    },
    overrideCustomComboConfigurations() {
      const { customize } = this.menu
      if (customize) {
        const { combos } = customize
        if (combos) {
          const combo = combos.filter(c => c.combo_uuid === this.comboUUID)[0]
          if (combo) {
            this.modalName = combo.rules.name[this.lang]
            this.maxExtra = combo.rules.max_extra
          }
        }
      }
    },
    setModalFromCartProduct() {
      let productInQuestion = this.cGroup.find(
        g => g.item.uuid === this.menu.uuid
      )

      if (productInQuestion) {
        productInQuestion = productInQuestion.item
        const possibleFirst = productInQuestion.choosedFirst //This is not the same shape as first_layers[]
        const possibleSecond = productInQuestion.choosedSecond

        //Make sure size/crust is the same
        this.choosetopping(possibleFirst, possibleSecond)

        //Iterate through extra toppings
        this.extraTopping
          .filter(t => !this.isSpecialTopping(t))
          .forEach((t, i) => {
            //Make this topping matches a previously added topping
            const result = productInQuestion.Add.filter(t2 => t2.id === t.id)
            if (t && result) {
              //Index matters here! Must match this.extraTopping's index
              //We also must call it for each instance found (i.e. there may be two instances of topping X)
              for (
                var x = 0;
                x < (result instanceof Array ? result.length : 1);
                x++
              ) {
                this.changeTopping(t, 2, i)
              }
            }
          })

        //Iterate through your toppings
        this.yourTopping
          .filter(t => !this.isSpecialTopping(t))
          .forEach((t, i) => {
            //Make sure this topping matches a previously removed topping
            const result = productInQuestion.Remove.find(t2 => t2.id === t.id)
            if (t && result) {
              //Index matters here! Must match this.yourTopping's index
              //We also must call it for each instance found (i.e. there may be two instances of topping X)
              for (
                var x = 0;
                x < (result instanceof Array ? result.length : 1);
                x++
              ) {
                this.changeTopping(t, 1, i)
              }
            }
          })

        const possibleCheese = productInQuestion.Add.find(t => t.special == 1)
        const possibleSauce = productInQuestion.Add.find(t => t.special == 2)

        //Set cheese
        if (possibleCheese) this.chooseCheese(possibleCheese)

        //Set sauce
        if (possibleSauce) this.chooseSauce(possibleSauce)

        this.updateDisableRemoveTopping()
        this.eventTrackingProductDetail(
          this,
          productInQuestion,
          this.$const.CARD_ITEM_TYPE.COMBO,
          'product-details',
          false,
          'deal',
          this.comboName
        )
      }

    },
    checkDisableExtra(uuid) {
      let exist = false
      for (let i in this.choosedCheese) {
        if (uuid === this.choosedCheese[i].uuid) {
          exist = true
        }
      }
      for (let i in this.choosedSauce) {
        if (uuid === this.choosedSauce.uuid) {
          exist = true
        }
      }
    },
    chooseSauce(val) {
      // Filter only when you have value observe UI
      if (val) {
        this.choosedSauce = val
        this.updateTotalPrice()

        if (this.showSauceChooser) this.toggleSauceChooser()
      }
    },
    chooseCheese(val) {
      // Filter only when you have value observe UI
      if (val) {
        this.choosedCheese = val
        this.updateTotalPrice()

        if (this.showCheeseChooser) this.toggleCheeseChooser()
      }
    },
    hasNonSpecialToppings(toppings) {
      return (
        toppings.some(topping => !this.isSpecialTopping(topping))
      )
    },
    /**
     * resize height modal popup
     * */
    resizePopup() {
      let headH =
        this.$el.querySelector('.modal-head').scrollHeight +
        this.$el.querySelector('.modal-action').scrollHeight
      if (window.innerWidth > 768) {
        headH = 350
      } else {
        headH -= 10
      }
      let maxH = window.innerHeight - headH
      this.$el.querySelector('.selector-container').style['max-height'] =
        maxH + 'px'
    },
    /**
     * Trigger before leave topping event
     */
    beforeLeaveTopping(el) {
      this.transitionLeave = false
    },
    /**
     * Trigger after leave topping event
     */
    afterLeaveTopping(el) {
      this.transitionLeave = true
    },
    /**
     * Chosen first layer - size
     */
    chooseSize(first) {
      this.choosedFirst = this.lodash.cloneDeep(first)
      this.choosedSecond = {}
      this.choosedName = choosedFirst.name
      this.updateTotalPrice()
      this.disableAdd = false
      let product = this.lodash.cloneDeep(this.menu)
      let choosedFirst = this.lodash.cloneDeep(first)

      product.first_layers = []
      product.first_layers.push(choosedFirst)
      product.price = this.totalPrice

      if (first != null && !this.empty(first)) {
        product.first_layers[0].second_layers = []
      }

      this.eventTrackingProductDetail(
        this,
        product,
        this.$const.CARD_ITEM_TYPE.COMBO,
        'product-list',
        false,
        'deal',
        this.comboName
      )
    },
    /**
     * Chosen first and second layer
     */
    choosetopping(first, second) {
      this.choosedFirst = first
      this.choosedSecond = second
      this.choosedName = first.name + ' ' + second.name
      this.$set(this.gotCheese, 'status', this.menu.extraCheese)
      this.$set(this.gotCheese, 'price', this.getCheese(first.name))
      this.disableAdd = false
      this.updateTotalPrice()
      let product = this.lodash.cloneDeep(this.menu)
      let choosedFirst = this.lodash.cloneDeep(first)
      let choosedSecond = this.lodash.cloneDeep(second)

      product.first_layers = []
      product.first_layers.push(choosedFirst)
      product.price = this.totalPrice

      if (first != null && !this.empty(first)) {
        product.first_layers[0].second_layers = [choosedSecond]
      }
      this.eventTrackingProductDetail(
        this,
        product,
        this.$const.CARD_ITEM_TYPE.COMBO,
        'product-details',
        false,
        'deal',
        this.comboName
      )

    },
    /**
     * Get cheese price belong size pizza
     * */
    getCheese(size) {
      let price = 0
      for (let cheese in this.extracheese) {
        if (this.extracheese[cheese].Size === this.convertString(size)) {
          price = this.extracheese[cheese].Price
        }
      }
      return price
    },
    /**
     * Get topping price
     * */
    getPriceTopping(topping) {
      let price = 0
      if (topping) {
        price = topping[this.choosedFirst.sku]
          ? topping[this.choosedFirst.sku]
          : 0
      } else {
        price = 0
      }

      return price
    },
    /**
     * Reset item choiced
     * */
    resetDataItem() {
      this.choosedFirst = {}
      this.choosedName = ''
      this.totalPrice = 0
      this.removeYourTopping = []
      this.addExtraTopping = []
      this.yourTopping = JSON.parse(JSON.stringify(this.defaultsTopping))
      this.extraTopping = JSON.parse(JSON.stringify(this.defaultsextraTopping))
      this.totalExtraTopping = 0
      // this.choosedCheese = []
      // this.choosedSauce = []
      this.showCheeseChooser = false
      this.showSauceChooser = false
    },
    /**
     * reset first and second layer
     * */
    changeSize() {
      this.resetDataItem()
      this.disableAdd = false
    },
    toggleSauceChooser() {
      this.showSauceChooser = !this.showSauceChooser
    },
    toggleCheeseChooser() {
      this.showCheeseChooser = !this.showCheeseChooser
    },
    /**
     * Slice topping
     * type: 1: your topping, 2 remove your topping, 3 add topping (default)
     * */
    sliceTopping(topping, type) {
      if (type === 1) {
        for (let y in this.yourTopping) {
          if (this.yourTopping[y].id === topping.id) {
            this.yourTopping.splice(y, 1)
            break
          }
        }
      } else if (type === 2) {
        for (let y in this.removeYourTopping) {
          if (this.removeYourTopping[y].id === topping.id) {
            this.removeYourTopping.splice(y, 1)
            break
          }
        }
      } else {
        for (let y in this.addExtraTopping) {
          if (this.addExtraTopping[y].id === topping.id) {
            this.addExtraTopping.splice(y, 1)
            break
          }
        }
      }
    },
    /**
     * change your topping
     * type: 1: remove topping, 2 add topping
     * */
    changeTopping(topping, type, index) {
      let newTopping = JSON.parse(JSON.stringify(topping))
      if (this.transitionLeave) {
        if (!topping.isDisable) {
          if (type === 1) {
            this.eventTrackingVariantUnSelect({
              item: this.menu,
              topping: topping,
              first: this.choosedFirst
            })
            this.yourTopping.splice(index, 1)
            if (
              !topping.default ||
              (topping.default && !this.checkToppingType(topping, 2))
            ) {
              this.sliceTopping(topping, 3)
              this.updateTotalPrice()
            } else {
              this.removeYourTopping.push(topping)
              this.extraTopping.unshift(newTopping)
            }
          } else if (type === 2) {
            // add them extra
            if (topping.default) {
              this.extraTopping = this.extraTopping.filter(function(
                value,
                index,
                arr
              ) {
                return value.id != topping.id
              })
            }
            this.eventTrackingVariantSelect({
              item: this.menu,
              topping: topping,
              first: this.choosedFirst
            })
            newTopping.default = this.checkToppingType(newTopping, 2)
            if (!newTopping.default) {
              this.addExtraTopping.push(newTopping)
              this.updateTotalPrice()
            } else {
              this.sliceTopping(topping, 2)
            }

            if (newTopping.default) {
              this.yourTopping.push(newTopping)
            } else {
              this.yourTopping.unshift(newTopping)
            }
          }

          this.updateExtra({ topping: newTopping, type: type })
          this.setActiveToppingList(this.extraTopping, this.yourTopping)
          this.checkDisableTopping(topping, type)
        }
      }
    },
    /**
     * update total price
     * */
    updateTotalPrice() {
      this.totalExtraTopping = 0
      for (let topping in this.addExtraTopping) {
        this.totalExtraTopping += parseFloat(
          this.getPriceTopping(this.addExtraTopping[topping].prices)
        )
      }
      this.totalPrice = this.menu.price > 0 ? parseFloat(this.menu.price) : 0
      this.totalPrice += parseFloat(this.totalExtraTopping)
      if (typeof this.choosedFirst.price !== 'undefined') {
        this.totalPrice = this.sumNumWithoutFormat(
          this.choosedFirst.price,
          this.totalPrice
        )
      }
      if (typeof this.choosedSecond.price !== 'undefined') {
        this.totalPrice = this.sumNumWithoutFormat(
          this.choosedSecond.price,
          this.totalPrice
        )
      }
      let extraPrice = 0
      // for (let i in this.choosedCheese) {
      if (this.choosedCheese && this.choosedCheese.prices) {
        extraPrice += parseFloat(
          this.choosedCheese.prices[this.choosedFirst.sku]
        )
      }
      if (this.choosedSauce && this.choosedSauce.prices) {
        extraPrice += parseFloat(
          this.choosedSauce.prices[this.choosedFirst.sku]
        )
      }
      // }
      this.totalPrice += parseFloat(extraPrice)
    },
    /**
     * set your and extra toppings
     * */
    getTopping() {
      let vm = this
      // Checking to disable add button
      if (this.menu.first_layers.length > 0) {
        this.disableAdd = true
      }

      let data = this.lodash.uniqBy(
        JSON.parse(JSON.stringify(this.menu.toppings.default)),
        'id'
      )
      let extraData = JSON.parse(JSON.stringify(this.menu.toppings.extra))
      for (let topping in data) {
        data[topping].default = true
        data[topping].isDisable = false
      }

      this.choosedCheese = data ? data.filter(x => x.special == 1)[0] : null
      this.choosedSauce = data ? data.filter(x => x.special == 2)[0] : null
      this.yourTopping =
        data !== null
          ? this.lodash.uniqWith(
              data.filter(x => !this.isSpecialTopping(x)),
              this.lodash.isEqual
            )
          : []
      this.extraTopping = extraData
        ? this.lodash.uniqWith(
            extraData
              .concat(data.filter(z => this.isSpecialTopping(z)))
              .filter(
                x => vm.yourTopping.filter(y => y.sku === x.sku).length == 0
              ),
            this.lodash.isEqual
          )
        : []

      this.defaultsTopping = JSON.parse(JSON.stringify(this.yourTopping))
      this.defaultsextraTopping = JSON.parse(JSON.stringify(this.extraTopping))

      this.updateDisableExtraTopping()
    },
    /**
     * check topping is extra or default
     * type: 1 isExtra, 2 Default
     * */
    checkToppingType(topping, type) {
      let i = 0
      for (let t in this.defaultsTopping) {
        if (this.defaultsTopping[t].id === topping.id) {
          i++
        }
      }
      if (i === 0) {
        return type === 1
      }
      i = 0
      for (let t in this.yourTopping) {
        if (this.yourTopping[t].id === topping.id) {
          i++
        }
      }
      if (type === 1) {
        return i > 0
      }
      return i === 0
    },
    /**
     * re-update extra and default toppings
     * type: 1 remove topping, 2 add topping
     * */
    updateExtra(topping, type) {
      for (var t in this.extraTopping) {
        if (this.extraTopping[t].id === topping.id) {
          if (type === 1) {
            if (topping.default && this.yourTopping.length > 0) {
              this.extraTopping[t].default = true
              for (var y in this.yourTopping) {
                if (this.yourTopping[y].id === topping.id) {
                  this.yourTopping[y].default = true
                  this.extraTopping[t].default = false
                  break
                }
              }
            } else {
              this.extraTopping[t].default = topping.default
            }
          } else {
            this.extraTopping[t].default = false
          }
        }
      }
    },
    /**
     * Check to show/hide extra topping
     * type: 1 remove topping, 2 add topping (default)
     * */
    checkDisableTopping(topping, type) {
      this.updateDisableRemoveTopping(topping)
      this.updateDisableExtraTopping(topping)
    },
    isExtraTopping(topping) {
      return !topping.default && topping.special == 0
    },
    disableExtraTopping(topping) {
      if (topping) {
        topping.isDisable = true
      }
    },
    /**
     * Checking to show/hide extra topping
     * */
    updateDisableExtraTopping(topping) {
      const disable =
        this.yourTopping.filter(this.isExtraTopping).length >= this.maxExtra
      this.extraTopping
        .filter(this.isExtraTopping)
        .forEach(topping => (topping.isDisable = disable || topping.isDisable))
      this.disableExtraTopping(topping)
    },
    updateDisableRemoveTopping() {
      const disable = this.removeYourTopping.length >= this.maxRemoval
      this.yourTopping.forEach(
        topping => (topping.isDisable = disable && topping.default)
      )
    },
    /**
     * Show extra topping
     * */
    showAllExtraTopping(s) {
      this.extraTopping.forEach(topping => (topping.isDisable = !flag))
    },
    isValidItem() {
      if (!this.menu.first_layers) {
        this.disableAdd = false
        return true
      }
      const extraToppings = this.yourTopping.filter(this.isExtraTopping)
      if (extraToppings.length < this.menu.min_extra) {
        this.errorMessage = this.$t('needs_more_extras', {
          more: this.menu.min_extra - extraToppings.length
        })
        return false
      }
      if (extraToppings.length > this.maxExtra) {
        this.errorMessage = this.$t('needs_fewer_extras', {
          more: extraToppings.length - this.maxExtra
        })
        return false
      }
      this.errorMessage = ''
      return true
    },
    fixFreeToppingName(name) {
      return name.split('-')[0]
    },
    preBasket() {
      if (this.choosedSecond.name === 'Pan Pizza de Sartén') {
        const costraCheddar = this.extraTopping.find(
          t => t.sku === 'costra-cheddar'
        )
        if (costraCheddar) {
          this.$modal.show(ModalUpsellConfirmation, {
            checkout: this.addToBasket,
            name: costraCheddar.name,
            toppingId: costraCheddar.id,
            currency: this.$const.CURRENCY,
            amount: this.getPriceTopping(costraCheddar.prices)
          })
        } else {
          this.addToBasket()
        }
      } else {
        this.addToBasket()
      }
    },
    addToBasket(upsellToppingIds = []) {
      let defaultToppings = JSON.parse(
        JSON.stringify(this.menu.toppings.default)
      )
      if (!this.disableAdd) {
        for (var cb in this.cGroup) {
          if (this.cGroup[cb].GroupId === this.id) {
            let price =
              this.choosedFirst.price && this.choosedFirst.price > 0
                ? this.choosedFirst.price
                : 0
            price +=
              this.choosedSecond.price && this.choosedSecond.price > 0
                ? this.choosedSecond.price
                : 0
            this.cGroup[cb].item.name =
              this.menu.name +
              ' ' +
              (this.choosedFirst.name != undefined
                ? this.choosedFirst.name
                : '')
            this.cGroup[cb].item.id = this.menu.id
            this.cGroup[cb].item.choosedSecond = this.choosedSecond
            this.cGroup[cb].item.choosedFirst = this.choosedFirst
            this.cGroup[cb].item.price = price
            this.cGroup[cb].item.totalPrice = this.totalPrice
            this.cGroup[cb].item.Default = this.defaultsTopping
            this.cGroup[cb].item.Remove = this.removeYourTopping
            // Separate default toppings and extra toppings before sending to cart
            let newExtraTopping = this.addExtraTopping
            if (
              defaultToppings &&
              defaultToppings.filter(x => x.sku === this.choosedCheese.sku)
                .length == 0
            ) {
              newExtraTopping = this.lodash.union(newExtraTopping, [
                this.choosedCheese
              ])
            }
            if (
              defaultToppings &&
              defaultToppings.filter(x => x.sku === this.choosedSauce.sku)
                .length == 0
            ) {
              newExtraTopping = this.lodash.union(newExtraTopping, [
                this.choosedSauce
              ])
            }
            if (upsellToppingIds && upsellToppingIds.length > 0) {
              newExtraTopping = [
                ...newExtraTopping,
                ...upsellToppingIds.map(id =>
                  this.extraTopping.find(tp => tp.id == id)
                )
              ].filter(e => e)
            }

            this.cGroup[cb].item.Add = newExtraTopping
            this.cGroup[cb].item.uuid = this.menu.uuid
            if (this.choosedCheese) {
              this.cGroup[cb].item.CheesePrice = this.gotCheese.price
            } else {
              this.cGroup[cb].item.CheesePrice = 0
            }
            this.cGroup[cb].item.image = this.menu.image.desktop_detail
              ? this.menu.image.desktop_detail
              : ''
          }
        }
        let pro = {
          item: this.menu,
          id: this.menu.uuid,
          uuid: this.menu.uuid,
          combo_uuid: this.comboUUID,
          name: this.menu.name,
          price: this.totalPrice,
          first: this.choosedFirst || {},
          second: this.choosedSecond || {}
        }
        this.evenTrackingProductClick(this, pro, 'add-to-deal')
        // this.eventTrackingAddToDeal(this, pro)
        this.$root.$emit('responseComboGroup', this.cGroup, this.id)
        this.$emit('close')
      }
    }
  }
}
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.remove-toppings-str {
  display: block;
}

.add-toppings-str {
  display: block;
}
</style>
