<template>
  <div data-synth="upsell-modal">
    <div
      class="confirm-modal"
      tabindex="-1"
      role="dialog"
      ref="vue_confirm_modal"
    >
      <div
        class="modal-dialog"
        style="max-width: 420px; z-index: 1010"
        role="document"
      >
        <div class="modal-content p20">
          <div class="modal-body text-center text-grey">
            <p class="t14" v-if="this.name">
              {{
                $t('modal.upsell_crust', {
                  name: this.name,
                  currency: this.currency,
                  amount: this.amount
                })
              }}
            </p>
            <p class="t14" v-else-if="!this.hasDrink">
              {{ $t('modal.upsell_drink') }}
            </p>
            <p class="t14" v-else-if="!this.hasDessert">
              {{ $t('modal.upsell_dessert') }}
            </p>
          </div>
          <div class="flex">
            <button
              type="button"
              data-synth="upsell-yes"
              class="btn btn-success p10 t17 mr20 ml20 fw600 flex-1"
              v-on="
                this.toppingId
                  ? {
                      click: () => checkoutAndCloseModal([this.toppingId])
                    }
                  : !this.hasDrink
                  ? {
                      click: () => redirectToSlug('drinks')
                    }
                  : { click: () => redirectToSlug('desserts') }
              "
            >
              {{ $t('button_add') }}
            </button>
            <button
              type="button"
              data-synth="upsell-no"
              class="btn btn-default p10 t17 mr20 ml20 fw600 flex-1"
              style="background: #e9ebee"
              data-dismiss="modal"
              @click="checkoutAndCloseModal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'hasDrink',
    'hasDessert',
    'checkout',
    'toggleCart',
    'name',
    'currency',
    'amount',
    'toppingId'
  ],
  methods: {
    checkoutAndCloseModal(id) {
      this.checkout(id)
      this.$emit('close')
    },
    redirectToSlug(slug) {
      let vm = this
      vm.redirectToMenuPage(slug)
      this.toggleCart(false)
      this.$emit('close')
    }
  },
  mounted() {
    let vm = this
  }
}
</script>
<style scoped>
.confirm-modal {
  padding-top: 50%;
}
</style>
